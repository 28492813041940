import React from 'react';
import logo from '../../assets/n_560px-LVM_Versicherung_2010__ (1).png';
import novabitlogo from '../../assets/Purple minimalist Tech Company Logo (8).png'
import ssl from '../../assets/SSL-certificate-image.png'
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const footerStyles = {
    width: '80%',
    padding: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginRight: '10%',
    gap: '120px',
  };

  const sectionStyles = {
    marginRight: '20px',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };

  const logoStyles = {
    width: '100px',
    marginBottom: '10px',
  };

  const addressStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  const addressIconStyles = {
    width: '20px',
    marginRight: '10px',
  };

  const openingHoursStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  const openingHoursIconStyles = {
    width: '20px',
    marginRight: '10px',
  };

  const imageStyles = {
    width: '100px',
  };

  const handleNovabitClick = () => {
    const confirmation = window.confirm('Möchten Sie die Novabit-Webseite besuchen?');
    if (confirmation) {
      window.open('https://www.novabit-studios.de/');
    }
  };
  return (
    !isMobile ? (
      <div>
        <footer style={footerStyles}>
          <section style={sectionStyles}>
            <img src={logo} alt="Logo" style={{ width: '120px', marginBottom: '-20px', marginTop: '40px' }} />
            <div style={addressStyles}>
              <p>
                <h4 style={{ fontSize: '1.1rem', fontWeight: '600' }}>LVM Ranft Assekuranzservice <br />
                  und -vermittlung GmbH &Co.KG</h4>
                <p style={{ marginTop: '-10px' }}>Leher Heerstr. 48/50 <br />
                  28359 Bremen <br />
                  Tel. 0421 1651670</p>
              </p>
            </div>
          </section>
          <section style={sectionStyles}>
            <br />
            <h3 style={{ fontSize: '1.3rem', fontWeight: '600', color: '#3A3846', marginTop: '20px' }}>Links</h3>
            <a style={{ color: '#3A3846', textDecoration: 'none', fontSize: '18px', marginBottom: '6px' }} href="#kontakt">Kontakt</a>
            <Link style={{ color: '#3A3846', textDecoration: 'none', fontSize: '18px', marginBottom: '6px' }} to="/impressum">Impressum</Link>
            <Link style={{ color: '#3A3846', textDecoration: 'none', fontSize: '18px', marginBottom: '6px' }} to="/offenlegungspflicht">Offenlegungspflicht</Link>
            <Link style={{ color: '#3A3846', textDecoration: 'none', fontSize: '18px', marginBottom: '6px' }} to="/datenschutz">Datenschutz</Link>
          </section>
          <section style={sectionStyles}>
            <br />
            <h3 style={{ fontSize: '1.3rem', fontWeight: '600', color: '#3A3846' }}>Öffnungszeiten</h3>
            <div style={openingHoursStyles}>
              {/* <img src={openingHoursIcon} alt="Opening Hours Icon" style={openingHoursIconStyles} /> */}
              <p style={{ fontSize: '18px', marginTop: '-10px', marginBottom: '40px', color: '#3A3846' }}>Mo. - Fr.: 9:00-13:00 <br />
                <div style={{ textAlign: 'right', }}>14:00-17:00</div>  <br />
                Sa.-So. Geschlossen
              </p>
            </div>
          </section>
          <section style={sectionStyles}>
            <img src={ssl} width={'50%'} />
          </section>

        </footer>
        <div style={{ textAlign: 'center',marginLeft: '200px', width: '15%', borderRadius: '20px 20px 20px 20px', backgroundColor: 'transparent', padding: '10px', color: 'black', marginBottom: '20px', paddingBottom: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ margin: '0', display: 'flex', marginLeft: '-78px'  }}>
            Social Media:
            <a style={{ color: 'black', marginLeft: '20px', alignItems: 'center' }} href="https://www.instagram.com/lvm_ranft_bremen/?hl=de"><InstagramIcon /></a>
            <a style={{ color: 'black', marginLeft: '5px', alignItems: 'center' }} href="https://www.facebook.com/lvmranftbremen/?locale=de_DE"><FacebookIcon /></a>
          </p>
        </div>
        <hr />


        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '20px', marginTop: '12px' }}>
  <p style={{ marginLeft: '230px', marginBottom: '20px' }}>LVM Ranft Assekuranzservice und -vermittlung 
    GmbH &Co.KG. Alle Rechte vorbehalten
  </p>
  <a href="https://www.novabit-studios.de">
    <img src={novabitlogo} width={'90%'} style={{ marginRight: '-700px', cursor: 'pointer' }} />
  </a>
</div>



      </div>
    ):

      //MOBILE PHONE  
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <img src={logo} width={'30%'} alt="logo" /> <br />
        <h3>
          LVM Ranft Assekuranzservice <br />
          und -vermittlung GmbH &Co.KG</h3>
        <p>Leher Heerstr. 48/50 <br />
          28359 Bremen <br />
          Tel. 0421 1651670 </p> <br />

          <h3>Links</h3>
        <a style={{color:'black', textDecoration:'none'}} href="#kontakt">Kontakt</a> <br />
        <Link style={{color:'#3A3846', textDecoration:'none',fontSize:'18px', marginBottom: '6px'}} to="/impressum">Impressum</Link> <br />
        <Link style={{color:'#3A3846', textDecoration:'none',fontSize:'18px', marginBottom: '6px'}} to="/offenlegungspflicht">Offenlegungspflicht</Link> <br />
        <Link style={{color:'#3A3846', textDecoration:'none',fontSize:'18px', marginBottom: '6px'}} to="/datenschutz">Datenschutz</Link> <br />

        <h3>Öffnungszeiten</h3>
        <p>Mo. - Fr.: 9:00-13:00 <br />
                      14:00-17:00 <br />
            Sa.-So. Geschlossen
          </p> <br />
          <div style={{ textAlign: 'center',marginLeft: '150px', width: '15%', borderRadius: '20px 20px 20px 20px', backgroundColor: 'white', padding: '15px', color: 'black', marginBottom: '20px', paddingBottom: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ margin: '0', display: 'flex', marginLeft: '-78px'  }}>
            <a style={{ color: 'black', marginLeft: '80px', alignItems: 'center' }} href="https://www.instagram.com/lvm_ranft_bremen/?hl=de"><InstagramIcon /></a>
            <a style={{ color: 'black', marginLeft: '5px', alignItems: 'center' }} href="https://www.facebook.com/lvmranftbremen/?locale=de_DE"><FacebookIcon /></a>
          </p>
        </div>
       

          <img src={ssl} width={'30%'} /> <hr />
          <h5>LVM Ranft Assekuranzservice und -vermittlung <br />
          GmbH &Co.KG. Alle Rechte vorbehalten
          </h5>
          
          <img src={novabitlogo} href="https://www.novabit-studios.de" style={{marginTop: '0px', maginBottom: '15px'}}width={'40%'} />
    </div>
  );
};

export default Footer;
